.App {
  text-align: center;
}

#root {
  position: relative;
  min-height: 100vh;
}

#footer-main {
  position: sticky;
  top: 100vh;
}

.img-290 {
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

.img-180 {
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;
}

.img-200 {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.border-transparent {
  border-bottom-color: transparent !important;
}

.map-view-card {
  cursor: pointer;
}

.outer-hidden {
  top: 0;
  border-bottom: none !important;
  opacity: 0;
}

.date-selector-open,
.map-view-open {
  overflow: hidden;
}

#date-toggle-outer:not(.outer-hidden) {
  margin-bottom: 30px;
}

#date-toggle-outer:not(.outer-hidden) #date-toggle {
  padding-top: 30px;
}

#date-toggle .MuiButtonBase-root {
  transition-duration: .15s;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.outer-hidden #date-toggle {
  transition: none !important;
  padding-top: 0;
  padding-bottom: 0;
}

.outer-hidden #date-toggle .MuiButtonBase-root {
  padding-top: 0;
  padding-bottom: 0;
}

#root-container {
  overflow: initial;
  width: 100%;
}

#map-container > div:first-child {
  height: 75% !important;
}

.header-menu-lg {
  display: none;
}

.header-menu-element {
  padding: 13px 20px;
}

.header-menu-element-container {
  cursor: pointer !important;
}

.product-selector-select .loading {
  opacity: 0;
}

.header-menu-element-container:hover > .header-menu-element {
  color: #1F57B5;
}

.header-menu-lg:not(.active) {
  color: #0FA9EDA3;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1024px) {
  .home-header .flex-width {
    width: 78%;
  }

  .home-header .home-header-inner {
    padding-bottom: 50px;
  }

  .home-header .home-header-card-wrap {
    padding-top: 30px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .home-header .home-header-card-wrap-location-title {
    padding-top: 25px;
  }

  .home-header .home-header-card-wrap-outer {
    padding-top: 40px;
  }

  .hide-on-1024 {
    display: none;
  }
}

@media (max-width: 1023px) {
  .home-header .home-header-card-wrap-outer {
    padding-top: 20px;
  }

  .hide-on-1023 {
    display: none;
  }

  .home-header {
    background-image: none !important;
    min-height: initial !important;
  }
}

@media (min-width: 920px) {
  .hide-scrollbar.desktop-100-height {
    max-height: 100%;
  }
}

@media (min-width: 720px) {
  .increase-image-height {
    height: 150px !important;
  }

  .book-user-data-container {
    padding-left: 40px !important;
  }

  .max-width-360 {
    max-width: 400px;
    padding: 0;
    padding-right: 20px;
    margin: 0 auto;
    margin-right: 0;
  }

  .book-sum-card-desktop {
    border-radius: 22px;
    margin-left: 20px;
    margin-right: 20px;
    border: 2px solid #AED0FF;
  }

  .no-padding-top-desktop {
    padding-top: 0 !important;
  }

  .no-padding-bottom-desktop {
    padding-bottom: 0 !important;
  }

  .no-padding-right-desktop {
    padding-right: 0 !important;
  }

  .padding-40-desktop {
    padding-top: 40px;
  }

  .hide-on-mobile {
    display: block;
  }

  .hide-on-mobile.increase-padding-top {
    padding-top: 40px;
  }

  .increase-padding-top {
    padding-top: 40px;
  }

  .hide-on-desktop {
    display: none;
  }

  .footer-hide-on-mobile {
    display: block;
  }

  .footer-hide-on-desktop {
    display: none;
  }
}

@media (max-width: 719px) {
  .fix-padding-mobile {
    padding-left: 20px;
  }

  .footer-hide-on-mobile {
    display: none;
  }

  .footer-hide-on-desktop {
    display: block;
  }

  .hide-on-mobile {
    display: none;
  }

  .hide-on-desktop {
    display: block;
  }
}

@media (min-width: 720px) {
  .complete-booking-btn-container {
    transform: none !important;
    position: initial !important;
    padding-bottom: 30px;
  }

  .booking-last-cb-container {
    padding-bottom: 20px !important;
  }

  .header-menu-lg {
    display: flex;
  }

  #home-continue-btn {
    opacity: 0 !important;
  }
}

@media (max-width: 500px) {
  .map-view-card {
    max-width: 313px;
  }
}

@media (min-width: 920px) {
  #anim-box-map-view {
    height: 100% !important;
    top: 0 !important;
  }

  #map-middle-box {
    max-width: 500px;
    right: 0;
    margin: 0 0 0 auto;
  }

  .map-view-card {
    max-width: 380px;
  }

  #map-container > div:first-child {
    height: 100% !important;
    max-height: 800px;
  }

  #map-container > div:last-child {
    margin-bottom: -24px;
  }
}

@media (min-width: 720px) and (max-width: 919px) {
  #map-middle-box {
    max-width: 500px;
    right: 0;
    margin: 0 auto;
  }

  .map-view-card {
    max-width: 380px;
  }

  #map-container > div:first-child {
    height: 100% !important;
    max-height: 800px;
  }

  #map-container > div:last-child {
    margin-bottom: -24px;
  }
}

@media (min-width: 919px) {
  .date-toggle-container {
    max-width: 919px;
    margin: 0 auto;
  }

  .header-menu-lg {
    display: flex;
  }

  div.home-background {
    position: absolute;
    top: 0;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background: #C7EEFF;
    height: calc(100% - 80px);
    width: calc(100vw - 15px) !important;
    z-index: -1;
  }
}

@media (min-width: 720px) {
  .book-review-container > div {
    position: sticky;
    top: /* 86px; */ 126px;
  }

  .footer-text {
    font-size: 16px !important;
  }
}

@media (max-width: 350px) {
  .mobile-shrink .MuiTypography-root,
  .mobile-shrink .MuiSelect-select {
    font-size: 11px;
  }

  .map-title-ellipsis {
    max-width: 174px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.phone-dropdown-error .flag-dropdown {
  padding-bottom: 21px !important;
}

.price {
  word-break: initial !important;
}

div.home-image-overlay {
  height: 100%;
  width: 100%;
  z-index: 1;
}

div.home-background {
  position: absolute;
  top: 0;
  left: 0;
  background: #C7EEFF;
  height: calc(100% - 80px);
  width: 100%;
  z-index: -1;
}

img.home-image {
  position: absolute;
  top: 0;
  height: calc(100% - 80px);
  left: 0;
  width: 100%;
  z-index: -1;
}

.home-image-overlay-text {
  text-align: left;
  padding-left: 30px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiDialog-root {
  z-index: 9999999999 !important;
}

.MuiTypography-root {
  user-select: none;
  cursor: default;
}

.auto-text input::placeholder {
  color: #4A7F96 !important;
  opacity: 1;
}

.date-toggle-container::-webkit-scrollbar {
  width: 0;
  display: none;
}

.date-toggle-container::after {
  content: "";
  padding-right: 7.5px;
}

#map-view-structures-container::after {
  content: "";
  padding-right: 6px;
}

.map-radius {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.gmnoprint,
.gm-bundled-control,
.gm-control-active {
  display: none !important;
}


.card-actions-container {
  transform: translate(0, -16px);
}

.react-tel-input {
  z-index: 100;
}

.flag-dropdown {
  top: 50% !important;
  transform: translateY(-50%);
  padding-left: 15px !important;
  /* padding-top: 12px !important; */
  padding-bottom: 4px !important;
}

.skeleton-loader {
  position: relative;
  overflow: hidden;
}

.skeleton-loader::after {
  content: "";
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .2) 20%, rgba(255, 255, 255) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}


.summary-date::first-letter {
  text-transform: uppercase;
}

#map-view-structures-container {
  user-select: none;
}

#map-view-structures-container::-webkit-scrollbar {
  display: none;
  width: 0;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #1F57B5;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
}

.map-marker.active .pin {
  background: #F1B600;
}

.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #FFF;
  position: absolute;
  border-radius: 50%;
}
.pulse {
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #1F57B5;
  box-shadow: 0 0 1px 2px #1F57B5;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.map-marker.active .pulse:after {
  -webkit-box-shadow: 0 0 1px 2px #F1B600;
  box-shadow: 0 0 1px 2px #F1B600;
}

@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-o-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
